import {
	helpers,
	required,
} from "@vuelidate/validators";

const group = {
	namespaced: true,

	state: {
		form: {
			group_name: null,
		},
		validationRules: {
			form: {
				group_name: {
					required: helpers.withMessage("Please Enter Group Name.", required),
				},

			}
		},
		vuelidateExternalResults: {
			form: {
				group_name: '',
			}
		},
		getters: {},
		mutations: {},
		actions: {},
	}
};

export default group;