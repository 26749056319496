import CryptoJS from 'crypto-js';

// Replace 'yourSecretKey' with your actual secret key
const secretKey = '123#$%';

export function encryptMessage(message) {
  const encrypted = CryptoJS.AES.encrypt(message, secretKey).toString();
  return encrypted;
}

export function decryptMessage(encryptedMessage) {
  const bytes = CryptoJS.AES.decrypt(encryptedMessage, secretKey);
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted;
}
